
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },
  created() {
    console.log('componentCreated', this);
  },
  beforeMount() {
    console.log('componentBeforeMount', this);
  },
  mounted() {
    console.log('componentMounted', this);
  },
  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },
  destroyed() {
    console.log('componentDestroyed', this);
  },
  data: function () {
    return {
      name: 'Jimmy',
      age: 25,
      like: [
        'Tennis',
        'Chess',
        'Football'
      ]
    };
  },
  methods: {
    openAlert: function () {
      var self = this;
      self.$app.dialog.alert('Hello World');
    }
  },
  on: {
    pageMounted: function (e, page) {
      console.log('pageMounted', page);
    },
    pageInit: function (e, page) {
      console.log('pageInit', page);
    },
    pageBeforeIn: function (e, page) {
      console.log('pageBeforeIn', page);
    },
    pageAfterIn: function (e, page) {
      console.log('pageAfterIn', page);
    },
    pageBeforeOut: function (e, page) {
      console.log('pageBeforeOut', page);
    },
    pageAfterOut: function (e, page) {
      console.log('pageAfterOut', page);
    },
    pageBeforeRemove: function (e, page) {
      console.log('pageBeforeRemove', page);
    }
  },
  id: 'd96e8fba7d',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page">\n    <div class="navbar">\n      <div class="navbar-bg"></div>\n      <div class="navbar-inner sliding">\n        <div class="left">\n          <a href="#" class="link back">\n            <i class="icon icon-back"></i>\n            <span class="if-not-md">Back</span>\n          </a>\n        </div>\n        <div class="title">Component Page</div>\n      </div>\n    </div>\n    <div class="page-content">\n      <div class="block block-strong">\n        <p>Component page is alos compiled with Template7, but it has much more functionality. In addition to Template7 page it has lifecycle hooks, events handling and data managment.</p>\n        <p>It is useful to use Component page when you need page-specific logic.</p>\n      </div>\n      <div class="block-title">Events Handling</div>\n      <div class="block block-strong">\n        <a href="#" class="button button-raised" @click="openAlert">Open Alert</a>\n      </div>\n      <div class="block-title">Page Component Data</div>\n      <div class="block block-strong">\n        <p>Hello! My name is ';
      r += c(ctx_1.name, ctx_1);
      r += '. I am ';
      r += c(ctx_1.age, ctx_1);
      r += ' years old.</p>\n        <p>I like to play:</p>\n        <ul>\n          ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.like, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n          <li>';
          r += c(ctx_2, ctx_2);
          r += '</li>\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </ul>\n      </div>\n      <div class="block-title">Extended Context</div>\n      <div class="block block-strong">\n        <p>Component page context as Template7 page context is also extended with some additional variables.</p>\n        <h4>$route</h4>\n        <p>Contains properties of the current route:</p>\n        <ul style="padding-left:25px">\n          <li><b>$route.url</b>: ';
      r += c(ctx_1.$route.url, ctx_1);
      r += '</li>\n          <li><b>$route.path</b>: ';
      r += c(ctx_1.$route.path, ctx_1);
      r += '</li>\n          <li><b>$route.params</b>: ';
      r += Template7Helpers.js.call(ctx_1, 'return JSON.stringify(this.$route.params)', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</li>\n          <li><b>$route.hash</b>: ';
      r += c(ctx_1.$route.hash, ctx_1);
      r += '</li>\n          <li><b>$route.query</b>: ';
      r += Template7Helpers.js.call(ctx_1, 'return JSON.stringify(this.$route.query)', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</li>\n        </ul>\n\n        <h4>$root</h4>\n        <p>Root data & methods:</p>\n        <ul style="padding-left:25px">\n          <li><b>$root.user.firstName</b>: ';
      r += c(ctx_1.$root.user.firstName, ctx_1);
      r += '</li>\n          <li><b>$root.user.lastName</b>: ';
      r += c(ctx_1.$root.user.lastName, ctx_1);
      r += '</li>\n          <li><a @click="$root.helloWorld()">$root.helloWorld()</a></li>\n        </ul>\n\n        <h4>$theme</h4>\n        <p>Currently active theme:</p>\n        <ul style="padding-left:25px">\n          <li><b>$theme.ios</b>: ';
      r += c(ctx_1.$theme.ios, ctx_1);
      r += '</li>\n          <li><b>$theme.md</b>: ';
      r += c(ctx_1.$theme.md, ctx_1);
      r += '</li>\n          <li><b>$theme.aurora</b>: ';
      r += c(ctx_1.$theme.aurora, ctx_1);
      r += '</li>\n        </ul>\n      </div>\n    </div>\n  </div>\n';
      return r;
    }(this);
  },
  style: `
  p {
    margin: 10px 0;
  }
`,
  styleScoped: false
};
    