export default {
  data: function () {
    return { dataAccount: dbGetUser() };
  },
  id: '32efecac48',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">My Account</div>\n    </div>\n  </div>\n  <div class="page-content">\n    <div class="block">\n      <div class="list accordion-list">\n      <ul>\n        <li class="accordion-item">\n          <a href="#" class="item-link item-content">\n            <div class="item-inner">\n              <div class="item-title">Name: ';
      r += c(ctx_1.dataAccount.name, ctx_1);
      r += '</div>\n            </div>\n          </a>\n          <div class="accordion-item-content">\n            <div class="block">\n              <div class="list no-hairlines-md">\n                <ul>\n                  <li class="item-content item-input">\n                    <div class="item-inner">\n                      <div class="item-title item-floating-labelx"> Name</div>\n                      <div class="item-input-wrap">\n                        <input type="text" placeholder="" value="';
      r += c(ctx_1.dataAccount.name, ctx_1);
      r += '" name="">\n                        <span class="input-clear-button"></span>\n                      </div>\n                    </div>\n                  </li>\n                </ul>\n              </div>\n            </div>\n          </div>\n        </li>\n        <li class="accordion-item">\n          <a href="#" class="item-link item-content">\n            <div class="item-inner">\n              <div class="item-title">Email: ';
      r += c(ctx_1.dataAccount.email, ctx_1);
      r += '</div>\n            </div>\n          </a>\n          <div class="accordion-item-content">\n            <div class="block">\n              <div class="list no-hairlines-md">\n                <ul>\n                  <li class="item-content item-input">\n                    <div class="item-inner">\n                      <div class="item-title item-floating-labelx">Email</div>\n                      <div class="item-input-wrap">\n                        <input type="text" placeholder="" value="';
      r += c(ctx_1.dataAccount.email, ctx_1);
      r += '" name="">\n                        <span class="input-clear-button"></span>\n                      </div>\n                    </div>\n                  </li>\n                </ul>\n              </div>\n            </div>\n          </div>\n        </li>\n        <li class="accordion-item">\n          <a href="#" class="item-link item-content">\n            <div class="item-inner">\n              <div class="item-title">Password: * * * * * *</div>\n            </div>\n          </a>\n          <div class="accordion-item-content">\n            <div class="block">\n              <div class="list no-hairlines-md">\n                <ul>\n                  <li class="item-content item-input">\n                    <div class="item-inner">\n                      <div class="item-title item-floating-labelx">Password</div>\n                      <div class="item-input-wrap">\n                        <input type="password" placeholder="" value="" name="">\n                        <span class="input-clear-button"></span>\n                      </div>\n                    </div>\n                  </li>\n                   <li class="item-content item-input">\n                    <div class="item-inner">\n                      <div class="item-title item-floating-labelx">Password</div>\n                      <div class="item-input-wrap">\n                        <input type="password" placeholder="" value="" name="">\n                        <span class="input-clear-button"></span>\n                      </div>\n                    </div>\n                  </li>\n                </ul>\n              </div>\n            </div>\n          </div>\n        </li>\n        <li class="accordion-item">\n          <a href="#" class="item-link item-content">\n            <div class="item-inner">\n              <div class="item-title">Role: ';
      r += c(ctx_1.dataAccount.role, ctx_1);
      r += '</div>\n            </div>\n          </a>\n        </li>\n      </ul>\n    </div>\n    </div>\n  </div>\n</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};