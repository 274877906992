export default {
  data: function () {
    var dataAuthorities = dbGetAuthorities('all');
    dataAuthorities = JSON.stringify(dataAuthorities);
    return { dataAuthorities: dataAuthorities };
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      var app = self.$app;
    }
  },
  methods: {
    downloadContent(e) {
      var a = document.createElement('a');
      var blob = new Blob([this.dataAuthorities], { type: 'text/plain                                                                                                                                                                                                                                      ' });
      var url = URL.createObjectURL(blob);
      a.setAttribute('href', url);
      a.setAttribute('download', 'JudgeWriterBackup.txt');
      a.click();
    }
  },
  id: '13efbc13a0',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n<div class="page" data-name="about">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">Back Up</div>\n    </div>\n  </div>\n  <div class="page-content">\n    <div class="block-title">Data Back Up</div>\n    <div class="block block-strong">\n        <div class="text-editor text-editor-init" data-placeholder="Enter text...">\n            <div class="text-editor-content" contenteditable>\n                <p>';
      r += c(ctx_1.dataAuthorities, ctx_1);
      r += '</p>\n            </div>\n        </div>\n        <div class="block">\n            <div class="row">\n              <button class="col-33 button button-fill button-round" @click="downloadContent">Download</button>\n            </div>\n          </div>\n    </div>\n  </div>\n</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};