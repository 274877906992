
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var self = this;
    var app = self.$app;
    var $ = this.$;
    var timestamp = Date.now();
    var judgment = {
      judgment_id: String(timestamp),
      case_id: this.$route.params.caseId,
      case_number: '',
      case_title: '',
      author_id: localStorage.getItem('JUDGEWRITER_USERID'),
      author_name: localStorage.getItem('JUDGEWRITER_NAME'),
      team_id: '1',
      template_id: '',
      content: '',
      date_created: moment().format('llll'),
      date_updated: '',
      sync_timestamp: '',
      category: ''
    };
    return {
      dataCase: '',
      dataCaseId: this.$route.params.caseId,
      dataTemplates: '',
      dataJudgment: judgment,
      dataContent: ''
    };
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      var smartSelect1 = app.smartSelect.create({
        closeOnSelect: true,
        el: '.select-type'
      });
      var smartSelect = app.smartSelect.create({
        closeOnSelect: true,
        el: '.select-template'
      });
    },
    pageAfterin: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      var arrTemplates = [];
      self.dataCase = dbGetCase(this.$route.params.caseId);
      this.dataJudgment.case_title = this.dataCase.applicant + ' vs ' + this.dataCase.respondent;
      self.$update();
      arrTemplates = dbGetTemplates();
      self.dataTemplates = arrTemplates;
      self.$update();
    }
  },
  methods: {
    updateType(e) {
      this.dataJudgment.type = e.target.value;
      this.$update();
    },
    updateTitle(e) {
      this.dataJudgment.case_title = e.target.value;
      this.$update();
    },
    updateTemplate(e) {
      this.dataJudgment.template_id = e.target.value;
      for (var i = 0; i < this.dataTemplates.length; i++) {
        if (this.dataTemplates[i].template_id == this.dataJudgment.template_id) {
          this.dataJudgment.content = this.dataTemplates[i].content;
          this.dataContent = decodeURIComponent(this.dataTemplates[i].content);
          this.dataJudgment.case_number = this.dataCase.case_number;
          console.log('content saved');
        }
      }
      this.$update();
    },
    saveJudgment() {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      var timestamp = Date.now();
      if (dbSaveJudgment(this.dataJudgment))
        app.views.main.router.back({});
    }
  },
  id: '4c06340a89',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page">\n    <div class="navbar">\n      <div class="navbar-bg"></div>\n      <div class="navbar-inner sliding">\n        <div class="left">\n          <a href="#" class="link back">\n            <i class="icon icon-back"></i>\n            <span class="if-not-md">Back</span>\n          </a>\n        </div>\n        <div class="title">Create Judgement</div>\n      </div>\n    </div>\n    <div class="fab fab-extended fab-center-bottom">\n      <a href="" @click="saveJudgment">\n        <i class="icon f7-icons if-not-md">plus</i>\n        <i class="icon material-icons md-only">edit</i>\n        <div class="fab-text">Add Judgment</div>\n      </a>\n    </div>\n    <div class="page-content">\n      <div class="block">\n        <div class="block-title">';
      r += c(ctx_1.dataCase.applicant, ctx_1);
      r += ' vs ';
      r += c(ctx_1.dataCase.respondent, ctx_1);
      r += '</div>\n          <div class="list">\n            <ul>\n              <li>\n                <a class="item-link smart-select smart-select-init select-type">\n                  <select name="sel-template" @change="updateType">\n                    <option value="" selected>Select Type</option>\n                    <option value="JUDGMENT">Judgment</option>\n                    <option value="RULING">Ruling</option>\n                  </select>\n                  <div class="item-content">\n                    <div class="item-inner">\n                      <div class="item-title">Type</div>\n                    </div>\n                  </div>\n                </a>\n              </li>\n              <li>\n                <a class="item-link smart-select smart-select-init select-template">\n                  <select name="sel-template" @change="updateTemplate">\n                    <option value="" selected>Select Template</option>\n                    ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.dataTemplates, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <option value="';
          r += c(ctx_2.template_id, ctx_2);
          r += '">';
          r += c(ctx_2.title, ctx_2);
          r += '</option>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                  </select>\n                  <div class="item-content">\n                    <div class="item-inner">\n                      <div class="item-title">Selected Template</div>\n                    </div>\n                  </div>\n                </a>\n              </li>\n              <li class="item-content item-input">\n                <div class="item-inner">\n                  <div class="item-title item-floating-labelx"> Name</div>\n                  <div class="item-input-wrap">\n                    <input type="text" @change="updateTitle" value="';
      r += c(ctx_1.dataJudgment.case_title, ctx_1);
      r += '" name="">\n                    <span class="input-clear-button"></span>\n                  </div>\n                </div>\n              </li>\n            </ul>\n          </div>\n          <div class="block">\n            <div class="card">\n              <div class="card-content card-content-padding elevation-2">\n                ';
      r += c(ctx_1.dataContent, ctx_1);
      r += '\n              </div>\n            </div>\n          </div>\n      </div>\n    </div>\n  </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    