
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var self = this;
    var app = self.$app;
    var $ = this.$;
    return {
      dataJudgment: '',
      dataContent: '',
      dataType: '',
      dataJudgmentId: this.$route.params.judgmentId,
      dataUnsubscribe: '',
      dataAuthorities: ''
    };
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      this.$setState({ dataJudgment: self.dataJudgment });
      initSample();
      $('.popup-authorities').on('popup:open', function (e, popup) {
        self.dataAuthorities = dbGetAuthorities('all');
        self.$update();
      });
    },
    pageAfterin: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      var jud = dbGetJudgment(self.dataJudgmentId);
      self.dataJudgment = jud;
      self.dataType = jud.type;
      self.dataContent = decodeURIComponent(jud.content);
      CKEDITOR.instances.editor.setData(decodeURIComponent(jud.content));
      self.$update();
    },
    pageBeforeout: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
    }
  },
  methods: {
    saveJudgment() {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      console.log('GLOBAL IS', GlobalEditorContent);
      var editor_data = CKEDITOR.instances.editor.getData();
      self.dataJudgment.content = encodeURIComponent(editor_data);
      app.preloader.show();
      dbstore.collection('judgments').doc(self.dataJudgmentId).set(this.dataJudgment).then(function () {
        app.preloader.hide();
        if (!self.notificationFull) {
          self.notificationFull = self.$app.notification.create({
            icon: '<i class="icon material-icons">check</i>',
            title: 'JudgeWriter',
            text: 'Judgment has been saved!',
            closeTimeout: 2000
          });
        }
        self.notificationFull.open();
      }).catch(function () {
        console.log('Save failed');
      });
    },
    deleteJudgment() {
      var app = this.$app;
      var judgmentId = this.$route.params.judgmentId;
      app.dialog.confirm('Are you sure?', function () {
        dbDeleteJudgment(judgmentId);
        app.views.main.router.back({});
      });
    },
    getText: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      var editor_data = CKEDITOR.instances.editor.getData();
      this.dataJudgment.content = encodeURIComponent(editor_data);
      if (!self.notificationFull) {
        self.notificationFull = self.$app.notification.create({
          icon: '<i class="icon material-icons">check</i>',
          title: 'JudgeWriter',
          text: 'Judgment has been saved!',
          closeTimeout: 2000
        });
      }
      self.notificationFull.open();
    },
    generateWord: function () {
      const doc = new docx.Document();
      var docBlob = Export2Doc('editor', 'word-content');
      saveAs(docBlob, this.dataJudgment.type + ' - ' + this.dataJudgment.case_title + '.docx');
    }
  },
  id: '0cb704c9b2',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n<div class="page" data-name="about">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">Write ';
      r += c(ctx_1.dataType, ctx_1);
      r += '</div>\n    </div>\n  </div>\n  <div class="fab fab-right-top">\n    <a href="#">\n      <i class="icon f7-icons if-not-md">plus</i>\n      <i class="icon f7-icons if-not-md">xmark</i>\n      <i class="icon material-icons md-only">more_vert</i>\n      <i class="icon material-icons md-only">close</i>\n    </a>\n    <div class="fab-buttons fab-buttons-bottom">\n      <a href="" class="fab-label-button popup-open" data-popup=".popup-authorities">\n        <span>\n          <i class="material-icons">brightness_auto</i>\n        </span>\n        <span class="fab-label">AUTHORITIES</span>\n      </a>\n      <a href="" class="fab-label-button popup-open" @click="generateWord">\n        <span>\n          <i class="material-icons">file_download</i>\n        </span>\n        <span class="fab-label">EXPORT</span>\n      </a>\n      <a href="" class="fab-label-button" @click="deleteJudgment">\n        <span>\n          <i class="material-icons">delete_forever</i>\n        </span>\n        <span class="fab-label">DELETE ';
      r += c(ctx_1.dataType, ctx_1);
      r += '</span>\n      </a>\n\n    </div>\n  </div>\n  <!-- <div class="fab fab-extended fab-center-bottom">\n    <a href="#" @click="saveJudgment">\n      <i class="icon f7-icons if-not-md">plus</i>\n      <i class="icon material-icons md-only">save</i>\n    </a>\n  </div> -->\n  <div class="page-content">\n    <div class="block-title"><b>';
      r += c(ctx_1.dataType, ctx_1);
      r += ':</b> ';
      r += c(ctx_1.dataJudgment.case_title, ctx_1);
      r += '</div>\n    <div class="blockx block-strongx">\n      <div class="block">\n        <form>\n          <div id="editor" data-collection="judgments" data-id="';
      r += c(ctx_1.dataJudgmentId, ctx_1);
      r += '" data-caseTitle="';
      r += c(ctx_1.dataJudgment.case_title, ctx_1);
      r += '" data-caseId="';
      r += c(ctx_1.dataJudgment.case_id, ctx_1);
      r += '" data-caseNumber="';
      r += c(ctx_1.dataJudgment.case_number, ctx_1);
      r += '" data-type="';
      r += c(ctx_1.dataJudgment.type, ctx_1);
      r += '" data-templateId="';
      r += c(ctx_1.dataJudgment.template_id, ctx_1);
      r += '" data-teamId="';
      r += c(ctx_1.dataJudgment.team_id, ctx_1);
      r += '">\n            ';
      r += c(ctx_1.dataContent, ctx_1);
      r += '\n          </div>\n        </form>\n      </div>\n    </div>\n  </div>\n  <div class="popup popup-authorities">\n    <div class="page">\n      <div class="navbar">\n        <div class="navbar-bg"></div>\n        <div class="navbar-inner">\n          <div class="title">Authorities</div>\n          <div class="right"><a href="#" class="link popup-close">Close</a></div>\n        </div>\n      </div>\n      <div class="subnavbar">\n        <form data-search-container=".search-list" data-search-in=".item-title" class="searchbar searchbar-init">\n          <div class="searchbar-inner">\n            <div class="searchbar-input-wrap">\n              <input type="search" placeholder="Search"/>\n              <i class="searchbar-icon"></i>\n              <span class="input-clear-button"></span>\n            </div>\n            <span class="searchbar-disable-button if-not-aurora">Cancel</span>\n          </div>\n        </form>\n      </div>\n      <div class="page-content">\n        <div class="searchbar-backdrop"></div>\n        <div class="list simple-list searchbar-not-found">\n          <ul>\n            <li>Nothing found</li>\n          </ul>\n        </div>\n        <div class="block">\n          <div class="list contacts-list">\n            ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.dataAuthorities, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n            <div class="list media-list search-list searchbar-found">\n              <ul>\n                <li class="list-group-title">';
          r += c(ctx_2.letter, ctx_2);
          r += '</li>\n                ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.data, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                <li>\n                  <ax href="/legal-authorities-view/';
              r += c(ctx_3.authority_id, ctx_3);
              r += '/" class="item-linkx item-content">\n                    <div class="item-inner">\n                      <div class="item-title-row">\n                        <div class="item-title" style="color: purple;">';
              r += c(ctx_3.subject, ctx_3);
              r += '</div>\n                        <!-- <div class="item-after">';
              r += c(ctx_3.date, ctx_3);
              r += '</div> -->\n                      </div>\n                      <div class="item-subtitle"><b>';
              r += c(ctx_3.category, ctx_3);
              r += '</b>: ';
              r += c(ctx_3.source, ctx_3);
              r += '</div>\n                      <div class="item-textx">';
              r += c(ctx_3.content, ctx_3);
              r += '</div>\n                    </div>\n                  </ax>\n                </li>\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n              </ul>\n            </div>\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    