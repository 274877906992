
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var team = dbGetTeam();
    console.log(team);
    return { dataTeam: '' };
  },
  on: {
    pageAfterin: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      var arrUsers = [];
      dbstore.collection('users').where('team_id', '==', '1').orderBy('name').get().then(snapshot => {
        snapshot.docs.forEach(doc => {
          arrUsers.push(doc.data());
        });
        self.dataTeam = arrUsers;
        self.$update();
      });
    }
  },
  id: 'da15aa7aef',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n<div class="page" data-name="about">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">My Team</div>\n    </div>\n  </div>\n  <div class="fab fab-extended fab-center-bottom">\n    <a href="/new-member/">\n      <i class="icon f7-icons if-not-md">plus</i>\n      <i class="icon material-icons md-only">add</i>\n      <div class="fab-text">Add New Member</div>\n    </a>\n  </div>\n  <div class="page-content">\n    <div class="block block-strong">\n      <div class="list media-list chevron-center">\n        <ul>\n          ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.dataTeam, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n          <li>\n            <a href="/view-team-member/" class="item-link item-content">\n              <div class="item-inner">\n                <div class="item-title-row">\n                  <div class="item-title">';
          r += c(ctx_2.name, ctx_2);
          r += '</div>\n                  <div class="item-after">';
          r += c(ctx_2.role, ctx_2);
          r += '</div>\n                </div>\n                <div class="item-subtitle"> </div>\n              </div>\n            </a>\n          </li>\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </ul>\n      </div>\n    </div>\n  </div>\n</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    