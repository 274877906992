
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return { dataJudgments: '' };
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
    },
    pageAfterin: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      self.dataJudgments = dbGetJudgments('JUDGMENT');
      self.$update();
    },
    pageBeforeremove: function (e, page) {
      var self = this;
      var app = self.$app;
      var $ = this.$;
    }
  },
  id: '94c99ab0df',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n<div class="page" data-name="about">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">Judgments</div>\n    </div>\n  </div>\n  <div class="page-content">\n    <div class="card">\n      <div class="card-content card-content-padding">\n        <div class="list">\n          <ul>\n            ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.dataJudgments, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n            <li>\n              <a href="/write-judgment/';
          r += c(ctx_2.judgment_id, ctx_2);
          r += '/" class="item-link item-content">\n                <div class="item-inner">\n                  <div class="item-title">\n                    <div class="item-header">';
          r += c(ctx_2.author_name, ctx_2);
          r += ': ';
          r += c(ctx_2.date_created, ctx_2);
          r += '</div>\n                    ';
          r += c(ctx_2.case_title, ctx_2);
          r += '\n                    <div class="item-footer"> ';
          r += c(ctx_2.case_number, ctx_2);
          r += '</div>\n                  </div>\n                  <div class="item-after"><!-- Edit --></div>\n                </div>\n              </a>\n            </li>\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n          </ul>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    