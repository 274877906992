
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      dataAuthorities: dbGetAuthorities('instruments'),
      dataUnsubscribe: ''
    };
  },
  on: {
    pageBeforeRemove(e, page) {
      var self = this;
      if (self.listIndex) {
        self.listIndex.destroy();
      }
    },
    pageInit: function (e, page) {
      var self = this;
      var app = self.$app;
      self.listIndex = app.listIndex.create({
        listEl: self.$el.find('.list'),
        el: self.$el.find('.list-index'),
        indexes: 'auto',
        scrollList: true,
        label: true
      });
    },
    pageAfterin: function (e, page) {
      var self = this;
      var app = self.$app;
      var arrAuth = [];
      self.dataUnsubscribe = dbstore.collection('authorities').where('category', '==', 'instruments').orderBy('subject').onSnapshot(snapshot => {
        snapshot.forEach(function (doc) {
          var aut = doc.data();
          aut.id = doc.id;
          arrAuth.push(aut);
        });
        var list = [];
        var letter = 'A';
        for (var i = 0; i < 26; i++) {
          var section = {
            letter: letter,
            data: []
          };
          for (var j = 0; j < arrAuth.length; j++) {
            if (arrAuth[j].letter == letter) {
              section.data.push(arrAuth[j]);
            }
          }
          list.push(section);
          letter = String.fromCharCode(letter.charCodeAt() + 1);
        }
        self.dataAuthorities = list;
        self.$update();
      });
    },
    pageBeforeremove: function (e, page) {
      var self = this;
      var app = self.$app;
      self.dataUnsubscribe();
    }
  },
  id: '3ea04efa5e',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n<div class="page" data-name="about">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">Legal Authorities: Statutory Instruments</div>\n    </div>\n    <div class="subnavbar">\n      <form data-search-container=".search-list" data-search-in=".item-title" class="searchbar searchbar-init">\n        <div class="searchbar-inner">\n          <div class="searchbar-input-wrap">\n            <input type="search" placeholder="Search"/>\n            <i class="searchbar-icon"></i>\n            <span class="input-clear-button"></span>\n          </div>\n          <span class="searchbar-disable-button if-not-aurora">Cancel</span>\n        </div>\n      </form>\n    </div>\n  </div>\n  <div class="fab fab-extended fab-center-bottom">\n    <a href="/legal-authorities-new/0/">\n      <i class="icon f7-icons if-not-md">plus</i>\n      <i class="icon material-icons md-only">add</i>\n      <div class="fab-text">Add Authority</div>\n    </a>\n  </div>\n  <div class="list-index"></div>\n  <div class="page-content">\n    <div class="searchbar-backdrop"></div>\n    <div class="list simple-list searchbar-not-found">\n      <ul>\n        <li>Nothing found</li>\n      </ul>\n    </div>\n    <div class="block">\n      <div class="list contacts-list">\n        ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.dataAuthorities, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n        <div class="list media-list search-list searchbar-found">\n          <ul>\n            <li class="list-group-title">';
          r += c(ctx_2.letter, ctx_2);
          r += '</li>\n            ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.data, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n            <li>\n              <a href="/legal-authorities-view/';
              r += c(ctx_3.authority_id, ctx_3);
              r += '/" class="item-link item-content">\n                <div class="item-inner">\n                  <div class="item-title-row">\n                    <div class="item-title">';
              r += c(ctx_3.subject, ctx_3);
              r += '</div>\n                    <div class="item-after">';
              r += c(ctx_3.date, ctx_3);
              r += '</div>\n                  </div>\n                  <div class="item-subtitle">';
              r += c(ctx_3.source, ctx_3);
              r += '</div>\n                  <div class="item-text">';
              r += c(ctx_3.content, ctx_3);
              r += '</div>\n                </div>\n              </a>\n            </li>\n            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n          </ul>\n        </div>\n        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n      </div>\n    </div>\n  </div>\n</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    