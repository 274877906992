import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import Routes
import routes from './routes.js';

// Import main app component
import App from '../app.f7.html';

window.f7app = new Framework7({
  root: '#app', // App root element
  component: App, // App main component

  name: 'JudgeWriter', // App name
  theme: 'md', // Automatic theme detection

  data: function () { 
  return {
      //API_URL: 'http://localhost/apps/judgewriter-admin/api/',
      API_URL: 'https://ultracodesystems.dev/judgeAdmin/api/',
      UPLOAD_URL: 'http://localhost/apps/judgewriter-admin/upload/',
      AUTOSAVE_INTERVAL: '',
      ONLINE_SYNC_INTERVAL: ''
    };
  },
  on: {
    pageInit: function(){
      
    }
  },
  // App routes
  routes: routes,
  // Register service worker
  serviceWorker: {
    path: '/service-worker.js',
  },
});
