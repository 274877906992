
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var self = this;
    var app = self.$app;
    var $ = this.$;
    var timestamp = Date.now();
    timestamp = timestamp.toString();
    var caseId = this.$route.params.caseId;
    var noteId = this.$route.params.noteId;
    if (noteId == 0) {
      noteId = timestamp;
      var dNote = {
        title: '',
        date_updated: moment().format('llll'),
        content: '',
        sync_id: '',
        private: '',
        case_id: caseId,
        note_id: noteId
      };
    } else {
      dNote = dbGetNote(noteId);
    }
    return {
      dataNote: dNote,
      dataNoteId: noteId,
      dataContent: ''
    };
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      initSample1();
    },
    pageAfterin: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      console.log('Entered note ', self.dataNote);
      self.dataContent = self.dataNote.content;
      CKEDITOR.instances.editor1.setData(decodeURIComponent(self.dataNote.content));
      self.$update();
      console.log('Rerieved note ', self.dataNote);
    },
    pageBeforeout: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
    }
  },
  methods: {
    togglePrivate() {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      var toggle = app.toggle.get('.toggle');
      if (toggle.checked) {
        self.dataNote.private = 0;
      } else {
        self.dataNote.private = 1;
      }
      console.log(self.dataNote);
    },
    updateTitle(e) {
      this.dataNote.title = e.target.value;
      $('#editor1').attr('data-title', e.target.value);
      this.$update();
    },
    deleteNote() {
      var app = this.$app;
      var noteId = this.$route.params.noteId;
      var caseId = this.$route.params.caseId;
      app.dialog.confirm('Are you sure?', function () {
        dbDeleteNote(noteId);
        app.views.main.router.back({});
      });
    },
    generateWord: function () {
      const doc = new docx.Document();
      var docBlob = Export2Doc('editor1', 'word-content');
      saveAs(docBlob, 'Note - ' + this.dataNote.title + '.docx');
    }
  },
  id: '69b7ab71b8',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n<div class="page" data-name="about">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">View Note</div>\n    </div>\n  </div>\n  <div class="fab fab-right-top">\n    <a href="#">\n      <i class="icon f7-icons if-not-md">plus</i>\n      <i class="icon f7-icons if-not-md">xmark</i>\n      <i class="icon material-icons md-only">more_vert</i>\n      <i class="icon material-icons md-only">close</i>\n    </a>\n    <div class="fab-buttons fab-buttons-bottom">\n      <a href="" class="fab-label-button popup-open" @click="generateWord">\n        <span>\n          <i class="material-icons">file_download</i>\n        </span>\n        <span class="fab-label">EXPORT</span>\n      </a>\n      <a href="" class="fab-label-button" @click="deleteNote">\n        <span>\n          <i class="material-icons">delete_forever</i>\n        </span>\n        <span class="fab-label">DELETE </span>\n      </a>\n\n    </div>\n  </div>\n  <div class="page-content">\n    <div class="block">\n      <div class="row">\n        <div class="col-50 left">\n        </div>\n        <div class="col-50">\n          <span>Private (Only me) </span>\n                <label class="toggle toggle-init">\n                  <input type="checkbox" ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.dataNote.private, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'checked';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' @click="togglePrivate">\n                  <span class="toggle-icon"></span>\n                </label>\n          <span>Public (Whole team)</span>\n        </div>\n      </div>\n      \n      <div class="list no-hairlines-md">\n        <ul>\n          <li class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-floating-labelx">Note Title</div>\n                <div class="item-input-wrap">\n                  <input type="text" placeholder="" value="';
      r += c(ctx_1.dataNote.title, ctx_1);
      r += '" name="" @input="updateTitle">\n                  <span class="input-clear-button"></span>\n                </div>\n                <span style="font-size: 12px;">Author: ';
      r += c(ctx_1.dataNote.author_name, ctx_1);
      r += '</span>\n              </div>\n            </li>\n          </ul>\n        </div>\n        <div class="blockx block-strongx">\n          <div class="block">\n            <div id="editor1" data-collection="notes" data-id="';
      r += c(ctx_1.dataNote.note_id, ctx_1);
      r += '" data-caseId="';
      r += c(ctx_1.dataNote.case_id, ctx_1);
      r += '" data-title="';
      r += c(ctx_1.dataNote.title, ctx_1);
      r += '">\n              ';
      r += c(ctx_1.dataContent, ctx_1);
      r += '\n            </div>\n          </div>\n        </div>\n    </div>\n\n  </div>\n</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    