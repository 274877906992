
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var roleId = this.$route.params.roleId;
    var role = dbGetRole(roleId);
    console.log(role);
    return { dataRole: role };
  },
  id: 'ba9bbda195',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">Role</div>\n    </div>\n  </div>\n  <div class="page-content">\n    <div class="block">\n      <div class="list no-hairlines-md">\n        <ul>\n          <li class="item-content item-input">\n            <div class="item-inner">\n              <div class="item-title item-floating-labelx">Role Name</div>\n              <div class="item-input-wrap">\n                <input type="text" placeholder="" value="';
      r += c(ctx_1.dataRole.role_name, ctx_1);
      r += '" name="">\n                <span class="input-clear-button"></span>\n              </div>\n            </div>\n          </li>\n        </ul>\n      </div>\n      <div class="card">\n        <div class="card-header">Role Permissions</div>\n        <div class="card-content card-content-padding">\n          <div class="list simple-list">\n            <ul>\n              ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.dataRole.role_permissions, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n              <li>\n                <span>';
          r += c(ctx_2.permission_name, ctx_2);
          r += '</span>\n                <label class="toggle toggle-init">\n                  <input type="checkbox" ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.permission, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'checked';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>\n                  <span class="toggle-icon"></span>\n                </label>\n              </li>\n              ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            </ul>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    