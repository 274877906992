export default {
  data: function () {
    var self = this;
    var app = self.$app;
    var $ = this.$;
    return {
      dataNote: '',
      dataNoteTitle: ''
    };
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      var caseId = this.$route.params.caseId;
      const saveButton = document.getElementById('saveButton');
      saveButton.addEventListener('click', function () {
        console.log('Saving.........');
        editor.save().then(outputData => {
          console.log('Article data: ', outputData);
          dbSaveNote(caseId, '0', outputData, self.dataNoteTitle);
        }).catch(error => {
          console.log('Saving failed: ', error);
        });
      });
    }
  },
  methods: {
    updateTitle(e) {
      this.dataNoteTitle = e.target.value;
      this.dataNoteTitle.team_id = '1';
      this.$update();
    }
  },
  id: 'b035827ab6',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n<div class="page" data-name="about">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">View Note</div>\n    </div>\n  </div>\n  <div class="fab fab-extended fab-left-bottom color-teal" id="saveButton">\n    <a href="#" class="link back">\n      <!-- <i class="icon f7-icons if-not-md">arrow_down_circle_fill</i> -->\n      <i class="icon material-icons md-onlyx">save</i>\n    </a>\n  </div>\n  <div class="page-content">\n    <div class="block">\n      <div class="list no-hairlines-md">\n        <ul>\n          <li class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-floating-labelx">Note Title</div>\n                <div class="item-input-wrap">\n                  <input type="text" placeholder="" value="';
      r += c(ctx_1.dataNoteTitle, ctx_1);
      r += '" name="dataNoteTitle" @input="updateTitle">\n                  <span class="input-clear-button"></span>\n                </div>\n              </div>\n            </li>\n          </ul>\n        </div>\n        <div class="ce-example">\n        <div class="ce-example__content _ce-example__content--small">\n          <div id="editorjs"></div>\n        </div>\n      </div>\n    </div>\n\n  </div>\n</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};