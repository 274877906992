export default {
  data: function () {
    return {
      dataId: this.$route.params.authorityId,
      dataAuthority: dbGetAuthority(this.$route.params.authorityId)
    };
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      var app = self.$app;
    },
    pageAfterin: function (e, page) {
      var self = this;
      var app = self.$app;
    }
  },
  id: '6c24f2cebe',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n<div class="page" data-name="about">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">View Authority</div>\n    </div>\n  </div>\n  <div class="page-content">\n    <div class="block-title" style="text-transform: uppercase;">LEGAL AUTHORITIES >> ';
      r += c(ctx_1.dataAuthority.category, ctx_1);
      r += '</div>\n    <div class="block">\n      <div class="card">\n        <div class="card-header">';
      r += c(ctx_1.dataAuthority.subject, ctx_1);
      r += '</div>\n        <div class="card-content card-content-padding">';
      r += c(ctx_1.dataAuthority.content, ctx_1);
      r += '</div>\n        <div class="card-footer right"><span class="right"><b>Source: </b>';
      r += c(ctx_1.dataAuthority.source, ctx_1);
      r += '</span><a href="/legal-authorities-new/';
      r += c(ctx_1.dataId, ctx_1);
      r += '/" class="link">Edit</a></div>\n      </div>\n    </div>\n  </div>\n</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};