
import HomePage from '../pages/home.f7.html';
import AboutPage from '../pages/about.f7.html';
import BackupPage from '../pages/backup.f7.html';
import FormPage from '../pages/form.f7.html';

import MenuSettings from '../pages/menu-settings.f7.html';
import LeftPage2 from '../pages/left-page-2.f7.html';
import DynamicRoutePage from '../pages/dynamic-route.f7.html';
import RequestAndLoad from '../pages/request-and-load.f7.html';
import NotFoundPage from '../pages/404.f7.html';
import NewJudgement from '../pages/app-new-judgement.f7.html';
import CreateJudgement from '../pages/app-create-judgment.f7.html';
import WriteJudgement from '../pages/app-write-judgment.f7.html';
import ViewJudgement from '../pages/app-view-judgment.f7.html';
import NewCase from '../pages/app-new-case.f7.html';
import MyAccount from '../pages/app-my-account.f7.html';
import NewRole from '../pages/app-new-role.f7.html';
import NewMember from '../pages/app-new-member.f7.html';
import NewNote from '../pages/app-new-note.f7.html';
import ViewCase from '../pages/app-view-case.f7.html';
import ViewNote from '../pages/app-view-note.f7.html';
import NewTemplate from '../pages/app-new-template.f7.html';
import NewTemplateSections from '../pages/app-new-template-sections.f7.html';
import ViewTemplate from '../pages/app-view-template.f7.html';
import Judgements from '../pages/app-judgements.f7.html';
import Rulings from '../pages/app-rulings.f7.html';
import Templates from '../pages/app-templates.f7.html';
import Cases from '../pages/app-cases.f7.html';
import Team from '../pages/app-my-team.f7.html';
import Role from '../pages/app-role.f7.html';
import TeamRoles from '../pages/app-team-roles.f7.html';
import Notes from '../pages/app-notes.f7.html';
import LegalAuthorities from '../pages/app-legal-authorities.f7.html';
import LegalAuthoritiesLaws from '../pages/app-authorities-laws.f7.html';
import LegalAuthoritiesInstruments from '../pages/app-authorities-instruments.f7.html';
import LegalAuthoritiesArticles from '../pages/app-authorities-articles.f7.html';
import LegalAuthoritiesWords from '../pages/app-authorities-words.f7.html';
import LegalAuthoritiesView from '../pages/app-authorities-view.f7.html';
import LegalAuthoritiesNew from '../pages/app-authorities-new.f7.html';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/backup/',
    component: BackupPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },

  {
    path: '/menu-settings/',
    component: MenuSettings,
  },
  {
    path: '/left-page-2/',
    component: LeftPage2,
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/new-judgment/:caseId/',
    component: NewJudgement,
  },
  {
    path: '/create-judgment/',
    component: CreateJudgement,
  },
  {
    path: '/write-judgment/:judgmentId/',
    component: WriteJudgement,
  },
  {
    path: '/new-template/',
    component: NewTemplate,
  },
  {
    path: '/new-case/',
    component: NewCase,
  },
  {
    path: '/my-account/',
    component: MyAccount,
  },
  {
    path: '/new-role/',
    component: NewRole,
  },
  {
    path: '/new-member/',
    component: NewMember,
  },
  {
    path: '/new-note/:caseId/',
    component: NewNote,
  },
  {
    path: '/view-case/:caseId/',
    component: ViewCase,
  },
  {
    path: '/view-note/:noteId/:caseId/',
    component: ViewNote,
  },
  {
    path: '/new-template-sections/',
    component: NewTemplateSections,
  },
  {
    path: '/view-template/:templateId/',
    component: ViewTemplate,
  },
  {
    path: '/view-judgment/',
    component: ViewJudgement,
  },
  {
    path: '/judgements/',
    component: Judgements,
  },
  {
    path: '/rulings/',
    component: Rulings,
  },
  {
    path: '/templates/',
    component: Templates,
  },
  {
    path: '/cases/',
    component: Cases,
  },
  {
    path: '/my-team/',
    component: Team,
  },
  {
    path: '/team-roles/',
    component: TeamRoles,
  },
  {
    path: '/role/:roleId/',
    component: Role,
  },
  {
    path: '/notes/',
    component: Notes,
  },
  {
    path: '/legal-authorities/',
    component: LegalAuthorities,
  },
  {
    path: '/legal-authorities-laws/',
    component: LegalAuthoritiesLaws,
  },
  {
    path: '/legal-authorities-instruments/',
    component: LegalAuthoritiesInstruments,
  },
    {
    path: '/legal-authorities-words/',
    component: LegalAuthoritiesWords,
  },
  {
    path: '/legal-authorities-articles/',
    component: LegalAuthoritiesArticles,
  },
  {
    path: '/legal-authorities-view/:authorityId/',
    component: LegalAuthoritiesView,
  },
  {
    path: '/legal-authorities-new/:authorityId/',
    component: LegalAuthoritiesNew,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;