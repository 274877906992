export default {
  data: function () {
    var self = this;
    var app = self.$app;
    var $ = this.$;
    var timestamp = Date.now();
    var today = moment().format('llll');
    var newAuthority = {
      authority_id: String(timestamp),
      team_id: '1',
      category: '',
      letter: '',
      subject: '',
      content: '',
      source: '',
      date: today
    };
    return {
      dataAuthority: newAuthority,
      dataTimestamp: timestamp
    };
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      var docId = this.$route.params.authorityId;
      console.log('Current auth...', self.dataAuthority);
      if (docId) {
        var authDoc = dbGetAuthority(docId);
        self.dataAuthority = authDoc;
        self.dataTimestamp = authDoc.authority_id;
        self.$update();
      }
      $('#sel-authority-category').val(self.dataAuthority.category);
    }
  },
  methods: {
    updateSubject(e) {
      this.dataAuthority.subject = e.target.value;
      this.dataAuthority.letter = e.target.value.substring(0, 1).toUpperCase();
      this.dataAuthority.team_id = '1';
      this.$update();
    },
    updateCategory(e) {
      this.dataAuthority.category = e.target.value;
      this.$update();
    },
    updateSource(e) {
      this.dataAuthority.source = e.target.value;
      this.$update();
    },
    updateContent(e) {
      this.dataAuthority.content = e.target.value;
      this.$update();
    },
    saveAuthority() {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      console.log('Saving auth...', self.dataAuthority);
      app.preloader.show();
      var auth = dbSaveAuthority(this.dataAuthority);
      if (auth) {
        app.preloader.hide();
        app.views.main.router.navigate('/legal-authorities-laws/');
      }
    }
  },
  id: 'd70d654928',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n<div class="page" data-name="about">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">Authority</div>\n    </div>\n  </div>\n  <div class="fab fab-extended fab-left-bottom color-teal" id="">\n    <a href="" class="" @click="saveAuthority">\n      <!-- <i class="icon f7-icons if-not-md">arrow_down_circle_fill</i> -->\n      <i class="icon material-icons md-onlyx">save</i>\n    </a>\n  </div>\n  <div class="page-content">\n    <div class="block">\n      <div class="list no-hairlines-md">\n        <ul>\n          <li class="item-content item-input">\n            <div class="item-inner">\n              <div class="item-title item-label">Category:</div>\n              <div class="item-input-wrap input-dropdown-wrap">\n                <select placeholder="Please choose..." @change="updateCategory" id="sel-authority-category">\n                  <option value=""></option>\n                  <option value="laws">Laws</option>\n                  <!-- <option value="instruments">Statutory Instruments</option> -->\n                  <option value="articles">Articles</option>\n                  <option value="words">Words and Phrases</option>\n                </select>\n              </div>\n            </div>\n          </li>\n          <li class="item-content item-input">\n            <div class="item-inner">\n              <div class="item-title item-label">Subject: (Index letter: <b>';
      r += c(ctx_1.dataAuthority.letter, ctx_1);
      r += '</b>)</div>\n              <div class="item-input-wrap">\n                <input type="text" placeholder="" value="';
      r += c(ctx_1.dataAuthority.subject, ctx_1);
      r += '" name="" @input="updateSubject">\n              </div>\n            </div>\n          </li>\n          <li class="item-content item-input">\n            <div class="item-inner">\n              <div class="item-title item-label">Source:</div>\n              <div class="item-input-wrap">\n                <input type="text" placeholder="" value="';
      r += c(ctx_1.dataAuthority.source, ctx_1);
      r += '" name="" @input="updateSource">\n                <span class="input-clear-button"></span>\n              </div>\n            </div>\n          </li>\n          <li class="item-content item-input">\n          <div class="item-inner">\n            <div class="item-title item-label">Content:</div>\n            <div class="item-input-wrap">\n              <textarea name="bio" class="" @input="updateContent">';
      r += c(ctx_1.dataAuthority.content, ctx_1);
      r += '</textarea>\n            </div>\n          </div>\n        </li>\n          </ul>\n        </div>\n    </div>\n\n  </div>\n</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};