export default {
  data: function () {
    return { dataTemplate: dbGetTemplate(this.$route.params.templateId) };
  },
  on: {
    pageInit: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      initSample();
      this.$setState({ templateName: 'New Template' });
    }
  },
  methods: {
    updateSectionName(e) {
      this.sectionName = e.target.value;
      console.log(this.sectionName);
      this.$update();
    },
    updateSectionDesc(e) {
      this.sectionDec = e.target.value;
      this.$update();
    },
    updateSectionContent(e) {
      this.password = e.target.value;
      this.$update();
    },
    getText: function () {
      var self = this;
      var app = self.$app;
      var $ = this.$;
      var editor_data = CKEDITOR.instances.editor.getData();
      console.log(editor_data);
    }
  },
  id: '26f112327b',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n<div class="page">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">New Template Sections</div>\n    </div>\n  </div>\n  <div class="fab fab-extended fab-center-bottom">\n    <a href="#" @click="getText">\n      <i class="icon f7-icons if-not-md">plus</i>\n      <i class="icon material-icons md-only">save</i>\n      <div class="fab-text">Save Template</div>\n    </a>\n  </div>\n  <div class="page-content">\n    <div class="block block-strong">\n      <div class="block-title">';
      r += c(ctx_1.templateName, ctx_1);
      r += '</div>\n        <div class="rowx">\n          <div class="col">\n            <div>Each template is made up of sections. Add as many sections as you need to create the template.</div>\n            <div class="block">\n              <div id="editor">\n                <h1>Hello world!</h1>\n                <p>I\'m an instance of <a href="https://ckeditor.com">CKEditor</a>.</p>\n              </div>\n            </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};